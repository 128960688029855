<template>
  <section class="conOfinterviewsfill-height mb-5">
    <section class="fill-height">
      <v-container class="d-flex fill-height align-center">
        <v-row class="align-center">
          <v-col cols="5">
            <video-player :options="videoOptions" />
          </v-col>
          <v-col cols="7">
            <div class="interview-head">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            </div>
            <div class="interview-content">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut ero labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco
            </div>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="5">
            <video-player :options="videoOptions" />
          </v-col>
          <v-col cols="7">
            <div class="interview-head">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            </div>
            <div class="interview-content">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut ero labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco
            </div>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="5">
            <video-player :options="videoOptions" />
          </v-col>
          <v-col cols="7">
            <div class="interview-head">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            </div>
            <div class="interview-content">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut ero labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco
            </div>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="5">
            <video-player :options="videoOptions" />
          </v-col>
          <v-col cols="7">
            <div class="interview-head">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            </div>
            <div class="interview-content">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut ero labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import VideoPlayer from "@/modules/shared/components/video";

export default {
  data: () => ({
    videoOptions: {
      autoplay: false,
      muted: false,
      controls: true,
      preload: "metadata",
      sources: [
        {
          src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          type: "video/mp4",
        },
      ],
    },
  }),
  components: {
    VideoPlayer,
  },
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
@import "./_interviews.scss";
</style>
