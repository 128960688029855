<template>
  <div class="about">
    <v-col cols="12"> <h2>Excepteur sint occaeuiecat cupidatat.</h2> </v-col>
    <v-col cols="12">
      <p>
        Lorem ipsum dolor sit Lorem ipsum dolor sit amet, consectetur
        adipisicing elit, sed do eiusmod tempor incididunt ut ero labore et
        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
        ullamco poriti laboris nisi ut aliquip ex ea commodo consequat. Duis
        aute irure dolor in reprehenderit in uienply voluptate velit esse cillum
        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat norin
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut ero labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco poriti laboris nisi ut aliquip
        ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        uienply voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        Excepteur sint occaecat cupidatat norin proident, sunt in culpa qui
        officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,
        consectetur adipisicing elit, sed do eiusmod tempor incididunt ut ero
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco poriti laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in uienply voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat norin proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur
        adipisicing elit, sed do eiusmod tempor incididunt ut ero labore et
        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
        ullamco poriti laboris nisi ut aliquip ex ea commodo consequat. Duis
        aute irure dolor in reprehenderit in uienply voluptate velit esse cillum
        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat norin
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut ero labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco poriti laboris nisi ut aliquip
        ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        uienply voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        Excepteur sint occaecat cupidatat norin proident, sunt in culpa qui
        officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,
        consectetur adipisicing elit, sed do eiusmod tempor incididunt ut ero
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco poriti laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in uienply voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat norin proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur
        adipisicing elit, sed do eiusmod tempor incididunt ut ero labore et
        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
        ullamco poriti laboris nisi ut aliquip ex ea commodo consequat. Duis
        aute irure dolor in reprehenderit in uienply voluptate velit esse cillum
        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat norin
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut ero labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco poriti laboris nisi ut aliquip
        ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        uienply voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        Excepteur sint occaecat cupidatat norin proident, sunt in culpa qui
        officia deserunt mollit anim id est laborum.
      </p>
    </v-col>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
