<template>
  <div class="eventSectionContainer my-3">
    <v-container>
      <v-row>
        <v-col md="6" cols="12">
          <v-date-picker
            v-model="dates"
            :event-color="'#FAEFEF'"
            range
            @click:date="getEvent"
            full-width
            no-title
          ></v-date-picker>
        </v-col>
        <v-col md="6" cols="12" class="conOfEvent">
          <div class="">
            <h3 class="headerEv">Events</h3>
            <v-row>
              <v-col cols="12" v class="py-1">
                <div class="contentEvent">
                  <div class="eventTitle">Lorem ipsum dolor</div>
                  <div class="eventDateTime">
                    <v-chip
                      class="ma-2"
                      color="#0CAA61"
                      label
                      text-color="white"
                    >
                      All Day</v-chip
                    >
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="py-1">
                <div class="contentEvent">
                  <div class="eventTitle">Lorem ipsum dolor</div>
                  <div class="eventDateTime">
                    <v-chip
                      class="ma-2"
                      color="#F2BF33"
                      label
                      text-color="white"
                    >
                      11Am-1pm</v-chip
                    >
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="py-1">
                <div class="contentEvent">
                  <div class="eventTitle">Lorem ipsum dolor</div>
                  <div class="eventDateTime">
                    <v-chip
                      class="ma-2"
                      color="#F2BF33"
                      label
                      text-color="white"
                    >
                      11Am-1pm</v-chip
                    >
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="py-1">
                <div class="contentEvent">
                  <div class="eventTitle">Lorem ipsum dolor</div>
                  <div class="eventDateTime">
                    <v-chip
                      class="ma-2"
                      color="#F2BF33"
                      label
                      text-color="white"
                    >
                      11Am-1pm</v-chip
                    >
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="py-1">
                <div class="contentEvent">
                  <div class="eventTitle">Lorem ipsum dolor</div>
                  <div class="eventDateTime">
                    <v-chip
                      class="ma-2"
                      color="#F2BF33"
                      label
                      text-color="white"
                    >
                      11Am-1pm</v-chip
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    dates: ["2021-12-10", "2021-12-20"],
    dateHaveEvents: [],
    listEvents: null,
    pickerDate: null,
    isLoading: false,
    eventDateObject: {
      month: "",
      day: "",
    },
    dateEvent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
  }),
  methods: {
    getEvent(date) {
      this.isLoading = true;
      this.listEvents = [];
      apiServices.getFilter("events-calendar", date).then((res) => {
        if (res.data) {
          this.listEvents = res.data;
          this.getMonthFromDate(date);
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    getMonthFromDate(date) {
      let getDate = new Date(date);
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      this.eventDateObject.month = months[getDate.getMonth()];
      this.eventDateObject.day = getDate.getDate();
    },
    getdateHaveEvents(date) {
      this.dateEvent = date;
      this.dateHaveEvents = [];

      let getDate = new Date(date.replace(/-/g, "/"));
      let fullYear = getDate.getFullYear();
      let monthNumber = ("0" + (getDate.getMonth() + 1)).slice(-2);
      apiServices
        .get("monthly-events-calendar" + "/" + fullYear + "/" + monthNumber)
        .then((res) => {
          if (res.data) {
            this.dateHaveEvents = res.data;
          }
        });
    },
    getUpcomingEvent() {
      apiServices.get("events/upcoming").then((res) => {
        if (res.data) {
          this.dateEvent = res.data[0].date;
          this.pickerDate = res.data[0].date;
          this.getdateHaveEvents(res.data[0].date);
          this.getEvent(res.data[0].date);
        }
      });
    },
    functionEvents(date) {
      const [, , day] = date.split("-");
      if (this.dateHaveEvents.includes(parseInt(day, 10))) return true;
      return false;
    },
  },
  created() {
    this.getUpcomingEvent();
  },
  watch: {
    pickerDate(newval, oldval) {
      if (newval != oldval && oldval) {
        this.getdateHaveEvents(newval);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_events-calender.scss";
</style>
