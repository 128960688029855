<template>
  <v-row class="fill-height">
    <v-col class="fill-height py-0" cols="12">
      <div class="videoStyle">
        <video-player :options="videoOptions" />
      </div>
    </v-col>
  </v-row>
</template>
<script>
import VideoPlayer from "@/modules/shared/components/video";

export default {
  props: ["video"],

  data: () => ({
    videoExist: false,
  }),
  components: {
    VideoPlayer,
  },
  computed: {
    videoOptions() {
      return {
        controls: true,
        autoplay: false,
        muted: false,
        sources: [
          {
            src: this.video.url,
            type: "video/mp4",
          },
        ],
      };
    },
  },
};
</script>
