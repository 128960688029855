<template>
  <section class="conOfMissionVission h-100 mb-5">
    <section>
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="conOfEachItem">
              <div class="titleContainerSec">
                <v-img
                  src="@/assets/img/focus.svg"
                  max-width="35"
                  alt="image Partners"
                ></v-img>
                <div class="titleSec">Vision</div>
              </div>
              <div class="descriptionContainerSec">
                Lorem ipsum dolor sit Lorem ipsum dolor sit amet, consectetur
                adipisicing elit, sed do eiusmod tempor incididunt ut ero labore
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco poriti laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                uienply voluptate velit esse cillum dolore eu fugiat nulla
                pariatur.
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="conOfEachItem">
              <div class="titleContainerSec">
                <v-img
                  src="@/assets/img/target.svg"
                  max-width="35"
                  alt="image Partners"
                ></v-img>
                <div class="titleSec">Goals</div>
              </div>
              <div class="descriptionContainerSec">
                Lorem ipsum dolor sit Lorem ipsum dolor sit amet, consectetur
                adipisicing elit, sed do eiusmod tempor incididunt ut ero labore
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco poriti laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                uienply voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat norin proident, sunt
                in culpa qui officia deserunt mollit anim id est laborum. Lorem
                ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut ero labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                poriti laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in uienply voluptate velit
                esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                occaecat cupidatat norin proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,
                consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut ero labore et dolore magna aliqua. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco poriti laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                in uienply voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat norin proident, sunt
                in culpa qui officia deserunt mollit anim id est laborum. Lorem
                ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut ero labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                poriti laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in uienply voluptate velit
                esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                occaecat cupidatat norin proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,
                consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut ero labore et dolore magna aliqua. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco poriti laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                in uienply voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat norin proident, sunt
                in culpa qui officia deserunt mollit anim id est laborum. Lorem
                ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut ero labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                poriti laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in uienply voluptate velit
                esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                occaecat cupidatat norin proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,
                consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut ero labore et dolore magna aliqua. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco poriti laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                in uienply voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat norin proident, sunt
                in culpa qui officia deserunt mollit anim id est laborum. Lorem
                ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut ero labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                poriti laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in uienply voluptate velit
                esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                occaecat cupidatat norin proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
export default {
  data: () => ({
    listItems: 0,
  }),
  components: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
@import "./_missionAndVision.scss";
</style>
