<template>
  <section class="conOfnews h-100 mb-5">
    <section>
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="news-poster">
              <img src="@/assets/img/news01.png" class="img-fluid" />
              <div class="news-title d-flex align-center">
                <div class="head">
                  Excepteur sint occaeuiecat cupidatat.
                  <div class="sub-head">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <div class="position">
              <img src="@/assets/img/news02.png" class="news-img" />
              <img src="@/assets/img/share.svg" class="share" />
            </div>
            <div class="news-head">Lorem ipsum dolor sit amet.</div>
            <div class="news-sub-head">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt
            </div>
          </v-col>
          <v-col cols="4">
            <div class="position">
              <img src="@/assets/img/news03.png" class="news-img" />
              <img src="@/assets/img/share.svg" class="share" />
            </div>
            <div class="news-head">Lorem ipsum dolor sit amet.</div>
            <div class="news-sub-head">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt
            </div>
          </v-col>
          <v-col cols="4">
            <div class="position">
              <img src="@/assets/img/news02.png" class="news-img" />
              <img src="@/assets/img/share.svg" class="share" />
            </div>
            <div class="news-head">Lorem ipsum dolor sit amet.</div>
            <div class="news-sub-head">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt
            </div>
          </v-col>
          <v-col cols="4">
            <div class="position">
              <img src="@/assets/img/news03.png" class="news-img" />
              <img src="@/assets/img/share.svg" class="share" />
            </div>
            <div class="news-head">Lorem ipsum dolor sit amet.</div>
            <div class="news-sub-head">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt
            </div>
          </v-col>
          <v-col cols="4">
            <div class="position">
              <img src="@/assets/img/news02.png" class="news-img" />
              <img src="@/assets/img/share.svg" class="share" />
            </div>
            <div class="news-head">Lorem ipsum dolor sit amet.</div>
            <div class="news-sub-head">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt
            </div>
          </v-col>
          <v-col cols="4">
            <div class="position">
              <img src="@/assets/img/news03.png" class="news-img" />
              <img src="@/assets/img/share.svg" class="share" />
            </div>
            <div class="news-head">Lorem ipsum dolor sit amet.</div>
            <div class="news-sub-head">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
export default {
  data: () => ({}),
  components: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
@import "./_news.scss";
</style>
