<template>
  <section class="conOfAnnualMeeting fill-height mb-5">
    <section class="fill-height">
      <v-container class="d-flex fill-height align-center">
        <v-row class="align-center">
          <img src="@/assets/img/partners01.png" />
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
export default {
  data: () => ({}),
  components: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
@import "./_partners.scss";
</style>
