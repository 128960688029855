<template>
  <div class="school">
    <div class="d-flex justify-center align-center">
      <button class="back" @click="$router.push({ path: 'schools' })">
        <v-icon> mdi-arrow </v-icon>
        Back to list
      </button>

      <img class="booth-img" src="@/assets/img/Booth01.png" />
      <img class="uni-img" src="@/assets/img/Future-University.png" />
      <div class="video-style">
        <video-player :options="videoOptions" />
      </div>
      <v-dialog v-model="dialog" max-width="700">
        <template v-slot:activator="{ on, attrs }">
          <button
            class="menu-btn"
            @click="pathByOaramSearch(11)"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="red-color"> mdi-menu </v-icon>

            Menu
          </button>
        </template>
        <v-card height="500">
          <v-card-title class="text-h6 grey-color">
            <v-col cols="6" class="pa-0">
              <span v-if="tabId === 11">
                <v-icon class="red-color"> mdi-menu </v-icon>
                Menu
              </span>
              <span v-else>
                <v-icon class="red-color" @click="back()">
                  mdi-arrow-left
                </v-icon>
                {{ tabName }}
              </span>
            </v-col>

            <v-col cols="6" class="pa-0 d-flex justify-end">
              <img src="@/assets/img/uni-logo.png" />
            </v-col>
          </v-card-title>

          <v-card-text class="conOfMenu scrollbar">
            <div class="d-flex align-center justify-center fill-height">
              <v-container fluid class="content-height">
                <About v-if="tabId === 1" />
                <Vision v-if="tabId === 2" />
                <News v-if="tabId === 3" />

                <Partners v-if="tabId === 4" />
                <Videos :videoExist="videoExist" v-if="tabId === 5" />
                <Fees v-if="tabId === 6" />
                <Calendar v-if="tabId === 7" />
                <Documents v-if="tabId === 9" />
                <Interviews v-if="tabId === 10 && school === true" />
                <v-row v-if="tabId === 11">
                  <v-col
                    cols="3"
                    class="ma-0 pa-0 d-flex align-center justify-center"
                    :key="tab.id"
                    v-for="tab in tabs"
                    @click="
                      pathByOaramSearch(tab.id, tab.name),
                        (tabName = tab.name),
                        (tabId = tab.id)
                    "
                  >
                    <div
                      class="tab text-center d-flex align-center justify-center"
                    >
                      <div>
                        <img :src="require(`@/assets/img/${tab.img}`)" />
                      </div>
                      <div>{{ tab.name }}</div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import About from "@/modules/shared/components/about-us";
import Vision from "@/modules/shared/components/vision";
import News from "@/modules/shared/components/news";

import Partners from "@/modules/shared/components/partners";
import Videos from "@/modules/shared/components/videos";
import Fees from "@/modules/shared/components/fees";
import Interviews from "@/modules/shared/components/interviews";

import Calendar from "@/modules/shared/components/events-calender";
import Documents from "@/modules/shared/components/forms-documents";
import VideoPlayer from "@/modules/shared/components/video";

export default {
  components: {
    About,
    Vision,
    News,
    Partners,
    Videos,
    Fees,
    Calendar,
    Documents,
    VideoPlayer,
    Interviews,
  },
  data() {
    return {
      dialog: false,
      school: true,
      tabId: 11,
      tabName: null,
      activeComp: null,
      updatedFilter: null,
      videoPlayer: null,
      videoExist: false,
      videoOptions: {
        autoplay: false,
        muted: false,
        controls: true,
        preload: "auto",
        sources: [
          {
            src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
            type: "video/mp4",
          },
        ],
      },
      video:
        "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      tabs: [
        {
          id: 1,
          name: "About us",
          img: "about.png",
        },
        {
          id: 2,
          name: "Vision & Goals",
          img: "vision.png",
        },
        {
          id: 3,
          name: "News",
          img: "news.png",
        },
        {
          id: 4,
          name: "Our partners",
          img: "partners.png",
        },
        {
          id: 5,
          name: "videos",
          img: "video.png",
        },
        {
          id: 6,
          name: "Fees",
          img: "money.png",
        },
        {
          id: 7,
          name: "Calendar",
          img: "calendar.png",
        },
        {
          id: 10,
          name: "Interview",
          img: "interview.png",
        },
        {
          id: 8,
          name: "Sessions",
          img: "sessions.png",
        },

        {
          id: 9,
          name: "Forms & Documents",
          img: "forms.png",
        },
      ],
    };
  },
  computed: {
    videoId() {
      return this.$store.getters.videoID;
    },
    videoName() {
      return this.$store.getters.videoName;
    },
  },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.getData();
        this.inzialize();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    inzialize() {
      this.tabId = parseInt(this.$route.query.activeComp) || null;
      this.tabName = this.$route.query.compName || null;
      this.videoPlayer = this.$route.query.videoPlayer || null;
      if (this.tabId === 5 && this.videoPlayer === "exist") {
        this.videoExist = true;
        this.tabName = this.videoName;
      } else {
        this.videoExist = false;
      }
      if (this.tabId) {
        this.dialog = true;
      }
    },
    pathByOaramSearch(id, name) {
      if (this.updatedFilter != id) {
        this.$router.replace({
          name: "school",
          query: { activeComp: id, compName: name },
        });
        this.updatedFilter = id;
      }
    },
    getData() {},
    back() {
      if (this.videoExist === true) {
        this.$store.commit("UPDATE_videoExist", false);
        this.$router.replace({
          name: "school",
          query: { activeComp: "5", compName: "videos" },
        });
      } else {
        this.tabId = 11;
        this.$router.replace({
          name: "school",
          query: { activeComp: "11" },
        });
      }
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "./_school.scss";
</style>
