<template>
  <section class="conOfvideos fill-height">
    <section class="fill-height">
      <v-row v-if="videoShow === false">
        <v-col cols="6" :key="video.id" v-for="video in videos">
          <div class="video-poster">
            <img :src="video.thumb" class="img-fluid" />
            <div class="video-title d-flex align-center">
              <v-row class="justify-center align-center">
                <v-col cols="9">
                  <div class="head">
                    {{ video.name }}
                    <div class="sub-head">{{ video.subname }}</div>
                  </div>
                </v-col>
                <v-col cols="2">
                  <div class="justify-end d-flex align-center">
                    <img
                      class="play"
                      src="@/assets/img/play.svg"
                      @click="currentVideo(video)"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
      <row>
        <CurrentVideo :video="video" v-if="videoShow === true" />
      </row>
    </section>
  </section>
</template>

<script>
import CurrentVideo from "./components/currentVideo";

export default {
  props: ["videoExist"],
  data: () => ({
    videos: [
      {
        id: 1,
        name: "  LOREM IPSUM DOLOR AMET",
        subname: "by Name Surname",
        url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        thumb: require("@/assets/img/poster.png"),
      },
      {
        id: 2,

        name: "  LOREM IPSUM DOLOR AMET",
        subname: "by Name Surname",
        url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        thumb: require("@/assets/img/poster.png"),
      },
      {
        id: 3,

        name: "  LOREM IPSUM DOLOR AMET",
        subname: "by Name Surname",
        url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        thumb: require("@/assets/img/poster.png"),
      },
      {
        id: 4,

        name: "  LOREM IPSUM DOLOR AMET",
        subname: "by Name Surname",
        url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        thumb: require("@/assets/img/poster.png"),
      },
      {
        id: 5,

        name: "  LOREM IPSUM DOLOR AMET",
        subname: "by Name Surname",
        url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        thumb: require("@/assets/img/poster.png"),
      },
    ],

    video: null,
    videoPlayer: null,
  }),
  components: {
    CurrentVideo,
  },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.inzialize();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    inzialize() {
      this.videoPlayer = this.$route.query.videoPlayer || null;

      if (this.videoPlayer) {
        this.dialog = true;
        this.videoShow = true;
      }
    },
    pathByOaramSearch(id, name) {
      this.$router.replace({
        name: "school",
        query: { activeComp: id, compName: name, videoPlayer: "exist" },
      });
    },
    currentVideo(item) {
      this.video = item;
      this.pathByOaramSearch("5", "videos");
      this.$store.commit("UPDATE_videoExist", true);
      this.$store.commit("UPDATE_videoName", item.name);
    },
  },
  computed: {
    videoShow() {
      return this.$store.getters.videoExist;
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import "./_videos.scss";
</style>
