<template>
  <div class="customTable">
    <div class="d-flex align-center justify-end">
      <button class="pdf-btn" @click="exportDataPdf()">Download As PDF</button>
    </div>

    <v-data-table
      id="content"
      :headers="headers"
      :items="grades"
      :items-per-page="5"
      hide-default-footer
      class="elevation-1 my-table data-table-border-radius-5"
    ></v-data-table>
    <!--  <div class="d-flex align-center justify-end">
      <v-pagination v-model="page" :length="4" circle></v-pagination>
    </div> -->
  </div>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  data: () => ({
    headers: [
      {
        text: "Grade",
        align: "start",
        sortable: false,
        value: "Grade",
      },
      { text: "Amount", value: "Amount", sortable: false },
      { text: "Start Date", value: "StartDate", sortable: false },
      { text: "Payment Method", value: "PaymentMethod", sortable: false },
    ],

    grades: [
      {
        Grade: "Grade1",
        Amount: "$3159",
        StartDate: "28 April 2019",
        PaymentMethod: "Payment via MasterCard",
      },
      {
        Grade: "Grade2",
        Amount: "$4237",
        StartDate: "22 April 2019",
        PaymentMethod: "Payment via Paypal",
      },
      {
        Grade: "Grade3",
        Amount: "$5262",
        StartDate: "26 April 2019",
        PaymentMethod: "Payment via MasterCard",
      },
      {
        Grade: "Grade4",
        Amount: "$6305",
        StartDate: "24 April 2019",
        PaymentMethod: "Payment via Paypal",
      },
      {
        Grade: "Grade5",
        Amount: "$7305",
        StartDate: "28 April 2019",
        PaymentMethod: "Payment via MasterCard",
      },
      {
        Grade: "Grade6",
        Amount: "$7305",
        StartDate: "28 April 2019",
        PaymentMethod: "Payment via MasterCard",
      },
      {
        Grade: "Grade7",
        Amount: "$8305",
        StartDate: "28 April 2019",
        PaymentMethod: "Payment via MasterCard",
      },
      {
        Grade: "Grade8",
        Amount: "$9305",
        StartDate: "28 April 2019",
        PaymentMethod: "Payment via MasterCard",
      },
      {
        Grade: "Grade9",
        Amount: "$16305",
        StartDate: "18 April 2019",
        PaymentMethod: "Payment via MasterCard",
      },
      {
        Grade: "Grade10",
        Amount: "$706305",
        StartDate: "26 April 2019",
        PaymentMethod: "Payment via MasterCard",
      },
    ],
  }),
  components: {},
  methods: {
    exportDataPdf() {
      let array = [];
      this.grades.forEach((el) => {
        array.push([el.Grade, el.Amount, el.StartDate, el.PaymentMethod]);
      });

      const doc = new jsPDF();
      doc.setFont("helvetica");
      doc.setFontSize(12);
      doc.text(7, 70, `Fees Report`);
      doc.autoTable({
        head: [["Grade", "Amount", "Start Date", "Payment Method"]],
        body: array,
        startY: 73,
        margin: { horizontal: 7 },
      });

      doc.save("fees.pdf");
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import "./_fees.scss";
</style>
